import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from '@mui/material/Collapse';
//import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';

import CopyToClipboardButton from "./CopyToClipBoardButton";
//import Dropdown from "./Dropdown";
import SubCard from "./SubCard";
import { Chip } from "@mui/material";

const initialUrl = "";
const minAttentionLvl = 0;
const maxAttentionLvl = 2;
const initialAttentionLvl = [minAttentionLvl, maxAttentionLvl];

//const statusList = ['Active Chat', 'Pending Response', 'Follow-up Needed', 'Check-In Message', 'Feedback Request', 'Session Completed'].map((c) => ({ label: c, value: c }));

let rows = [];
let country = 'ALL';

export default function DataTable() {
    const [data, setData] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('needsAttention');
    const [showFilters, setShowFilters] = useState(false);
    const [filterUrl, setFilterUrl] = useState(initialUrl);
    const [filterAttentionLvl, setFilterAttentionLvl] = useState(initialAttentionLvl);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [counters, setCounters] = useState([]);

    const addAppliedFilter = (filter) =>
        setAppliedFilters((current) => {
            if (current.includes(filter)) return current;
            return [...current, filter];
        });

    const removeAppliedFilter = (filter) =>
        setAppliedFilters((current) => current.filter((e) => e !== filter));

    const filtersCount = appliedFilters.length;

    const toggleShowFilters = () => setShowFilters((s) => !s);
    const resetFilters = () => {
        setAppliedFilters([]);
        setData(rows);
        setFilterUrl(initialUrl);
        setFilterAttentionLvl(initialAttentionLvl);
    };

    useEffect(() => {
        const getFormList = () => {
            country = new URL(window.location).pathname;
            country !== "/" ? country = country.replace("/", "") : country = "ALL";
            const myHeaders = new Headers();
            myHeaders.append("api-key", "723874ef-2aa9-4e1c-adc4-646a7d141c07");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cache-Control", "no-cache");

            const raw = JSON.stringify({
                country
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch("https://metyis-dashboard.pages.dev/get-forms", requestOptions)
                .then(async (res) => {
                    let formsData = [];
                    if (res.status === 200) {
                        const body = await res.text();
                        formsData = JSON.parse(body);
                        console.log('formsData', formsData);
                    }

                    setNeedsAttentionLvl(formsData);
                    //setCounters(getCounters());
                    setData(rows);
                    //rows = formsData;
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getFormList();
    }, []);

    const Range = ({
        value,
        setter,
        field,
        onChange,
        max,
        min,
        label,
        valueLabelFormatter
    }) => (
        <>
            <Typography id={`${field}-range-slider`} gutterBottom>
                {label}: {value[0]} - {value[1]}
            </Typography>
            <Slider
                value={value}
                onChange={(e, v) => {
                    setter(v);
                    if (onChange) onChange(e);
                }}
                valueLabelDisplay="auto"
                aria-labelledby={`${field}-range-slider`}
                max={max}
                min={min}
                getAriaValueText={valueLabelFormatter}
            />
        </>
    );

    useEffect(() => {
        const updatedRows = rows
            .filter((r) => {
                if (r.headers) return true;
                if (initialUrl !== filterUrl) {
                    addAppliedFilter("URL");
                    try {
                        const filterUrlRegEx = new RegExp(filterUrl, "gi");
                        return r.url.match(filterUrlRegEx);
                    } catch {
                        return r.url.indexOf(filterUrl) !== -1;
                    }
                }
                removeAppliedFilter("name");
                return true;
            })
            .filter((r) => {
                if (r.headers) return true;

                addAppliedFilter("calories");
                const [min, max] = filterAttentionLvl;
                return r['needsAttention'] >= min && r['needsAttention'] <= max;
            })

        setData(updatedRows);
        //setCounters(getCounters());
    }, [
        filterUrl,
        filterAttentionLvl
    ]);

    function setNeedsAttentionLvl(data) {
        const urls = [];
        const totals = [];

        let totalUrlsWithoutProblems = 0;
        let totalUrlsWithWarnings = 0;
        let totalUrlsWithProblems = 0;
        let totalUrlsWithForms = 0;

        console.log('data', data)

        for (const entry of data) {
            const bu = entry.BU;
            let urlsWithoutProblems = 0;
            let urlsWithWarnings = 0;
            let urlsWithProblems = 0;
            let urlsWithForms = 0;

            const formUrls = JSON.parse(entry.formUrls);

            for (const row of formUrls) {
                row.bu = bu;
                if (!row.forms) {
                    row.forms = [{ label: "", problems: [], fields: [] }];
                }

                if (row.forms[0]) {
                    if (row.forms[0].problems.includes('invalid labels') || row.forms[0].missingUtms) {
                        row['needsAttention'] = 2;
                        urlsWithProblems++;
                    } else if (row.forms[0].problems.length > 0) {
                        row['needsAttention'] = 1;
                        urlsWithWarnings++;
                    } else {
                        row['needsAttention'] = 0;
                        urlsWithoutProblems++;
                    }
                }

                urlsWithForms++;
                urls.push(row);
            }


            totals.push({ bu, urlsWithoutProblems, urlsWithWarnings, urlsWithProblems, urlsWithForms });

            totalUrlsWithoutProblems = totalUrlsWithoutProblems + urlsWithoutProblems;
            totalUrlsWithWarnings = totalUrlsWithWarnings + urlsWithWarnings;
            totalUrlsWithProblems = totalUrlsWithProblems + urlsWithProblems;
            totalUrlsWithForms = totalUrlsWithForms + urlsWithForms;
        }

        totals.push({ bu: 'Total', urlsWithoutProblems: totalUrlsWithoutProblems, urlsWithWarnings: totalUrlsWithWarnings, urlsWithProblems: totalUrlsWithProblems, urlsWithForms: totalUrlsWithForms });

        setCounters(totals);
        rows = urls;
    }

    function descendingComparator(a, b, orderBy) {
        if (typeof a[orderBy] === 'undefined') {
            if (typeof b[orderBy] === 'undefined') {
                return 0;
            }
            return 1;
        } else if (typeof b[orderBy] === 'undefined') {
            return -1;
        }

        switch (typeof b[orderBy]) {
            case "number":
            case "string":
            default:
                if (b[orderBy] < a[orderBy]) {
                    return -1;
                }
                if (b[orderBy] > a[orderBy]) {
                    return 1;
                }
                return 0;
        }
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
        //setCounters(getCounters());
    };

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow class="main-row" key={row.id} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Tooltip title={row ? row.url : ""}>
                            <>
                                {row.location ? row.location : ""}
                            </>
                        </Tooltip>
                        <CopyToClipboardButton value={row.url} />
                    </TableCell>
                    <TableCell align="center">{row.forms.length > 0 ? row.bu : ""}</TableCell>
                    <TableCell align="center">{row.forms.length > 0 ? row.forms[0].label : ""}</TableCell>
                    <TableCell align="center">{new Date(row.lastPageModification).toLocaleString('pt-PT')}</TableCell>
                    <TableCell align="center">{new Date(row.lastCheck).toLocaleString('pt-PT')}</TableCell>
                    <TableCell align="center"><Chip style={{ backgroundColor: row.needsAttention === 2 ? '#ffb6b6' : row.needsAttention === 1 ? '#fff700' : '#B9F6CA' }} label={(row.needsAttention === 2 ? '2 - Missing Important Info' : row.needsAttention === 1 ? '1 - Missing field validations' : '0 - No problems')}></Chip></TableCell>
                </TableRow>
                <TableRow class="collapsed-row">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 2 }}>
                                <Grid item xs={12} sm={6} sx={{ display: 'flex', marginBottom: '10px' }}>
                                    <SubCard title="Labels" sx={{ borderRadius: '8px', width: '-webkit-fill-available' }}>
                                        <Grid container direction="row" spacing={1} sx={{ width: '100%' }}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant="subtitle2" color={'grey'}>Form label</Typography>
                                                        <Typography variant="subtitle1" fontWeight={'bold'}>{row.forms[0] ? row.forms[0].label : ""}</Typography>
                                                    </Grid>
                                                    {/*
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography variant="subtitle2" color={'grey'}>Button label</Typography>
                                                        <Typography variant="subtitle1" fontWeight={'bold'}>""</Typography>
        </Grid>*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </SubCard>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ display: 'flex', marginBottom: '10px' }}>
                                    <SubCard title="Problems" sx={{ borderRadius: '8px', width: '-webkit-fill-available' }}>
                                        <Table>
                                            <TableBody>
                                                {row.forms[0].problems.map((problem) => (
                                                    <TableRow>
                                                        <TableCell align="center" key={problem}>{problem.replace('invalid labels' , 'Invalid Form Label')}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                                    <SubCard title="Fields" sx={{ borderRadius: '8px', width: '-webkit-fill-available' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"><Typography variant="subtitle2" fontWeight={'bold'}>Field Name</Typography></TableCell>
                                                    <TableCell align="center"><Typography variant="subtitle2" fontWeight={'bold'}>Validation</Typography></TableCell>
                                                    <TableCell align="center"><Typography variant="subtitle2" fontWeight={'bold'}>Required</Typography></TableCell>
                                                    <TableCell align="center"><Typography variant="subtitle2" fontWeight={'bold'}>type</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.forms[0].fields.map((field) => (
                                                    <React.Fragment>
                                                        <TableRow key={field.fieldName}>
                                                            <TableCell align="center">{field.fieldName}</TableCell>
                                                            <TableCell align="center">{field.validation ? 'yes' : 'no'}</TableCell>
                                                            <TableCell align="center">{field.required ? 'yes' : 'no'}</TableCell>
                                                            <TableCell align="center">{field.type}</TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const headCells = [
        {
            id: '',
            numeric: false,
            label: '',
        },
        {
            id: 'url',
            numeric: false,
            label: 'Url',
        },
        {
            id: 'bu',//business unit
            numeric: false,
            label: 'BU',
        },
        {
            id: 'type',
            numeric: false,
            label: 'Type',
        },
        {
            id: 'lastPageModification',
            numeric: false,
            label: 'Last Page Modification',
        },
        {
            id: 'lastCheck',
            numeric: false,
            label: 'Last Form Check',
        },
        {
            id: 'needsAttention',
            numeric: true,
            label: 'Needs Attention',
        },
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={headCell.id === 'url' ? { minWidth: '190px' } : { minWidth: '0px' }}
                            key={headCell.id}
                            align='center'
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'desc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">BU</TableCell>
                            <TableCell align="center">Total Urls with Forms</TableCell>
                            <TableCell align="center">Forms with no problems</TableCell>
                            <TableCell align="center">Forms with Warnings</TableCell>
                            <TableCell align="center">Forms with Problems</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {counters.map((counter) => (
                            <TableRow>
                                <TableCell align="center"><a target="_blank" rel="noreferrer" href={`https://metyis-dashboard.pages.dev/${counter.bu}`}>{counter.bu}</a></TableCell>
                                <TableCell align="center">{counter.urlsWithForms}</TableCell>
                                <TableCell align="center">{counter.urlsWithoutProblems}</TableCell>
                                <TableCell align="center">{counter.urlsWithWarnings}</TableCell>
                                <TableCell align="center">{counter.urlsWithProblems}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <Box m={2}>
                <Button
                    onClick={toggleShowFilters}
                    variant="contained"
                    disableElevation
                    endIcon={showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                >
                    {showFilters ? "Hide filters" : "Filter"}
                    {filtersCount > 0 ? ` (${filtersCount})` : ""}
                </Button>
                {filtersCount > 0 && (
                    <Button ml={2} onClick={resetFilters}>
                        Reset filters
                    </Button>
                )}
                {showFilters && (
                    <Box py={1}>
                        <Card variant="outlined">
                            <CardHeader
                                titleTypographyProps={{ variant: "h6" }}
                                title="Filter options"
                            />
                            <CardContent>
                                <Grid container spacing={4}>
                                    <Grid item container spacing={4}>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <TextField
                                                value={filterUrl}
                                                onChange={(e) => setFilterUrl(e.target.value)}
                                                label="Url"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Range
                                            label="Attention Lvl"
                                            value={filterAttentionLvl}
                                            setter={setFilterAttentionLvl}
                                            field="needsAttention"
                                            valueLabelFormatter={(v) => `${v} Level`}
                                            max={maxAttentionLvl}
                                            min={minAttentionLvl}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button onClick={resetFilters} size="small">
                                    Reset
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                )}
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 800 }}>
                        <Table stickyHeader aria-label="simple table">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {stableSort(data, getComparator(order, orderBy)).map((row) => (
                                    <Row align='center' key={row.location} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </React.Fragment>
    );
}

DataTable.defaultProps = {
    title: "No Title"
};
